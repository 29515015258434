import styled from 'styled-components';
import LinkArrowImage from '../../images/icon-home-arrow-purple.png';

export const Body = styled.div`
  background: #fff;
  color: #661988;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.02em;
`;
export const InnerBody = styled.div`
  width: 860px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
    height: auto;
    padding-top: 10px;
  }
`;

export const BodyContent = styled.div`
  width: 650px;
  margin: 0 auto;
  @media (max-width: 991px) {
    width: 100%;
    padding: 20px 35px 0 35px;
  }
`;

export const HorizontalBreak = styled.hr`
  height: 1px;
  border: none;
  background: #e5e5e5;
  margin: 50px 0;
  clear: both;
  opacity: 1;
  @media (max-width: 767px) {
    margin: 30px 0;
  }
`;

export const HeaderH1 = styled.h1`
  font-weight: normal !important;
  color: #6e2b8b;
  margin: 0 0 25px 0;
  text-transform: uppercase;
  font-family: 'Figtree-Bold', arial, sans-serif;
  font-size: 32px;
  line-height: 40px;
  @media (max-width: 991px) {
    font-size: 26px;
    line-height: 30px;
  }
`;

export const FullImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 35px;
`;

export const HeaderH2 = styled.h2`
  margin: 0 0 15px 0;
  padding: 0;
  font-weight: normal !important;
  text-align: center;
  font-family: 'Figtree-Medium', arial, sans-serif;
  font-size: 30px;
  line-height: 40px;
  color: #fec119;
  @media (max-width: 870px) {
    font-size: 22px;
    line-height: 32px;
  }
`;
export const UnorderedList = styled.ul`
  margin: 0;
  padding: 0;
  text-align: left;
`;

export const ListItem = styled.li`
  margin: 0;
  padding: 3px 0 3px 24px;
  list-style: none;
  background: url(${LinkArrowImage}) no-repeat 0 10px;
  background-size: 8px auto;
  font-size: 16px;
  line-height: 22px;
  color: #666;
  p {
    margin: 0;
    text-align: left;
  }
`;

export const ImageStyle = styled.img`
  margin-right: 30px;
  width: auto;
  height: auto;
  @media (max-width: 991px) {
    width: 140px;
    height: auto;
  }
`;

export const ParaText = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: #666;
  margin: 0 0 20px 0;
  padding: 0;
  b {
    font-weight: bold;
    font-family: 'Figtree-Bold', arial, sans-serif;
  }
`;
