import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import React from 'react';
import { useContentfulImages } from '../../hooks/useContentfulImages';
import * as Styled from './slpTmImageWithTextStyle';

const SlpTmImageWithText = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <Styled.HeaderH1>{children}</Styled.HeaderH1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.HeaderH2>{children}</Styled.HeaderH2>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <Styled.UnorderedList>{children}</Styled.UnorderedList>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <Styled.ListItem>{children}</Styled.ListItem>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulImages(node.data.target.sys.id);
        return asset.node.file.url.includes('image') ? (
          <Styled.ImageStyle src={asset.node.file.url} alt={asset.node.title} />
        ) : (
          <></>
        );
      },
      [BLOCKS.HR]: () => <Styled.HorizontalBreak />,
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <Styled.Body>
      <Styled.InnerBody>
        <Styled.BodyContent>
          <Styled.HeaderH1>{sectionData?.title}</Styled.HeaderH1>
          <Styled.FullImage
            src={sectionData?.image?.file?.url}
            alt={sectionData?.image?.file?.fileName}
          />
          {sectionData?.richText &&
            documentToReactComponents(
              JSON.parse(sectionData?.richText?.raw),
              optionsMainStyle
            )}
        </Styled.BodyContent>
      </Styled.InnerBody>
    </Styled.Body>
  );
};

export default SlpTmImageWithText;
